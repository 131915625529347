*,
*:after,
*:before {
  outline: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  width: 100%;
  font: 400 2.8vw/1.67 "UTMAvo", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  scroll-behavior: smooth;
  background: #e30faf;
  @media (min-width: 1024px) {
    font-size: 12.32px;
  }
}

body {
  // background: #cb00a5;
  color: #fff;
  position: relative;
  // height: 100vw;
  // overflow: hidden;
  &.not-scroll {
    overflow: hidden;
  }
}

img {
  display: block;
  width: 100%;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

button {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: inherit;
  background-color: transparent;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.wrapper {
  position: relative;
  @media (min-width: 1024px) {
    // width: 50vw;
    // max-width: 440px;
    width: 440px;
    margin: 0 auto;
  }
}

.sec-1 {
  background: url("../images/mb-sec-1-bg.jpg") no-repeat left top;
  background-size: 100% auto;
  padding-bottom: 15%;
}

.sec-2 {
  background: url("../images/mb-sec-2-bg.jpg") no-repeat left top;
  background-size: 100% auto;
  padding-bottom: 18%;
}

.sec-3 {
  background: url("../images/mb-sec-3-bg.jpg") no-repeat left top;
  background-size: 100% auto;
  padding-bottom: 25%;
}

.turn-content {
  display: none;
  &.active {
    display: block;
  }
}

.turn-content-2,
.turn-content-3 {
  &:after {
    content: "";
    display: block;
    width: 100%;
    padding: 0 0 50%;
  }
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.sec-2-title {
  width: 86.26%;
  margin: 0 auto 5%;
  cursor: pointer;
  opacity: 0;
  transform: translate(0, 50%);
}

.sec-2-circle {
  flex: 0 0 auto;
  width: 43%;
  margin-right: 4%;
  opacity: 0;
  transform: scale(0);
  &.bounce {
    animation: bounce 0.5s forwards;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  75% {
    opacity: 1;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.sec-2-items {
  display: flex;
  justify-content: center;
  margin: 0 0 4%;
  opacity: 0;
  transform: scale(0);
  &.bounce {
    animation: bounce 0.5s forwards;
  }
}

.sec-2-products {
  flex: 0 0 auto;
  width: 42.4%;
  cursor: pointer;
}

.step {
  width: 84.4%;
  margin: 0 auto 9%;
  opacity: 0;
  transform: translate(0, 20%);
}

.turns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 8%;
  opacity: 0;
  transform: translate(0, 40%);
}

.turn {
  flex: 0 0 auto;
  cursor: pointer;
  transition: width 0.3s;
  width: 15.86%;
  font-weight: 400;
  padding: 5% 0;
  text-align: center;
  border: solid 2px #fff;
  border-radius: 50%;
  white-space: nowrap;
  &.active {
    font-weight: 700;
    border-radius: 100px;
    font-size: 1.428rem;
    @media (min-width: 1024px) {
      font-size: 17.6px;
    }
    width: 30%;
    &.turn-1 {
      width: 54.93%;
    }
  }
}

.turn-1 {
  // width: 30%;
  margin-right: 2%;
  // border-radius: 100px;
  &.active {
    p {
      display: none;
    }
    p.active {
      display: block;
    }
  }
  p {
    display: block;
    &.active {
      display: none;
    }
  }
}

.turn-2 {
  // width: 15.86%;
  margin-right: 2%;
}

.turn-3 {
  // width: 15.86%;
}

.sec-3-title {
  margin: 0 auto 8%;
  width: 75.2%;
  opacity: 0;
  transform: translate(0, 200%);
}

.sec-3-text-1 {
  width: 79.2%;
  margin: 0 auto 10%;
  opacity: 0;
  transform: translate(0, 40%);
}

.sec-3-text-2 {
  width: 90%;
  margin: 0 auto;
  opacity: 0;
  transform: translate(0, 40%);
}

.tickets {
  width: 69.2%;
  margin: 0 auto 4%;
  opacity: 0;
  transform: scale(0);
  &.bounce {
    animation: bounce 0.5 forwards;
  }
}

.comming-soon {
  text-align: center;
  font-size: 2rem;
}

.diana-products {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 99999;
  background: url("../images/dt-bg-popup.jpg") no-repeat center;
  background-size: cover;
  pointer-events: none;
  align-items: flex-start;
  opacity: 0;
  transform: translate(0, 100%);
  transition: transform 0.3s, opacity 0.1s 0.3s;
  overflow: auto;
  justify-content: center;
  padding: 12vw 6vw;
  @media (min-width: 1024px) {
    padding: 5vw 0 4vw;
  }
  .content {
    position: relative;
  }
  ul {
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media (min-width: 1024px) {
      width: 82vw;
    }
  }
  li {
    flex: 0 0 auto;
    width: 50%;
    margin-bottom: 3vw;
    @media (min-width: 1024px) {
      width: 25%;
    }
  }
  &.show {
    pointer-events: all;
    opacity: 1;
    transform: translate(0, 0);
    transition: transform 0.3s;
  }
}
.teaser-bg {
  width: 100%;
  // height: 100vh;
  overflow: hidden;
  // background: #cb00a5 url("../images/mb-bg-home.jpg") no-repeat center top;
  // background-size: 100% auto;
  @media (min-width: 1024px) {
    // background: url("../images/dt-bg-home.jpg") no-repeat top center;
    // background-size: 100%;
    // height: 100vw;
  }
}

.close {
  position: absolute;
  top: 2vw;
  right: 2vw;
  width: 9.46vw;
  padding: 2vw;
  @media (min-width: 1024px) {
    width: 2.84vw;
    padding: 0;
  }
}

.btn-icon {
  background: transparent;
  border: none;
}

.bot {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  @media (min-width: 1024px) {
    .bot-img {
      display: none;
    }
  }
}

.btn-enter-code {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  a {
    display: block;
  }
  @media (min-width: 1024px) {
    width: 50vw;
    max-width: 440px;
  }
}

.top {
  position: absolute;
  top: 13.33vw;
  left: 50%;
  width: 95.33vw;
  transform: translate(-50%, 0);
  @media (min-width: 1024px) {
    width: 40.15625vw;
    top: 4vw;
    display: none;
  }
}
.mb-show {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  @media (min-width: 1024px) {
    display: none;
  }
}

.btn-open-popup-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 161% 0 0;
  width: 100%;
}

.btn-open-popup {
  display: block;
  width: 100%;
  padding: 0 0 78.4666666667%;
}
