@font-face {
  font-family: "UTMAvo";
  src: url("../fonts/UTMAvo.eot") format("embedded-opentype"),
    url("../fonts/UTMAvo.woff") format("woff"),
    url("../fonts/UTMAvo.ttf") format("truetype"),
    url("../fonts/UTMAvo.svg#UTMAvo") format("svg");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "UTMAvo";
  src: url("../fonts/UTMAvoBold.eot") format("embedded-opentype"),
    url("../fonts/UTMAvoBold.woff") format("woff"),
    url("../fonts/UTMAvoBold.ttf") format("truetype"),
    url("../fonts/UTMAvoBold.svg#UTMAvo") format("svg");
  font-weight: 700;
  font-display: swap;
}
